import React from "react"
import { Link } from "gatsby"
import NewsHeader from "../../components/blog/blogHeader"
import NewsFooter from "../../components/blog/blogFooter"
import Footer from "../../components/footer"
import SEO from "../../components/seo"

import insights from  "../../../assets/images/blog/insights/insights4.png"
import insights1 from  "../../../assets/images/blog/insights/insights1.png"
import insights2 from  "../../../assets/images/blog/insights/insights2.png"
import arrow from "../../../assets/images/servicesPages/arrow-right-min.png"


export default () => 
<div>
    <SEO title={'Brand Communities & Zero Party Data | ICON Worldwide'} 
    description="As business emerges into a new reality of remote customer relationships, a new type of brand community is taking shape. Discover Zero Party Data on our blog!"
    canonical={'https://icon-worldwide.com/blog/zero-party-data'}/>

    <NewsHeader/>
    <div id="insights">
    <div id="single-news">
            <h1>Brand Communities & Zero Party Data</h1>
            <div id="title-underline"></div>
        <div id="single-news-texts">
        <img src={insights} alt="Zero Party Data, ICON Worldwide Blog" title="ICON Worldwide Blog, Zero Party Data" style={{width:'100%', display:'block', margin:'0 0 30px'}}/>
            <p>As business emerges into a new reality of remote customer relationships, a new type of <strong>brand community</strong> is emerging.
            Brand communities create a forum for direct engagement based on shared interests, not just commerce. As Google joins
            other browsers in eliminating 3rd party cookies, these communities will help brands create truly personalised relationships
            based on <strong>Zero Party Data.</strong></p>

            <h2>Learn more:</h2>
            <p>
            Forbes: <a  href="https://www.forbes.com/sites/forbesagencycouncil/2020/03/23/is-zero-party-data-the-way-forward-for-personalized-marketing/?utm_source=hs_email&utm_medium=email&utm_content=2&_hsenc=p2ANqtz-_Cq869dTQ3l7MBRZiJq1-mh3rruXvOffdnElIXAi0TMYaZc0iVZQeJa4JARXR1Yleolxo31AR4jG2qUENikBAd2LLdUWyRyBjIeSSJkkEMc6gtJ8w&_hsmi=2#658151a4b324" target="_blank" rel="noopener noreferrer">"Zero-Party Data The Way Forward For Personalised Marketing"</a><br/>
            TechCrunch: <a  href="https://techcrunch.com/2020/01/14/google-wants-to-phase-out-support-for-third-party-cookies-in-chrome-within-two-years/" target="_blank" rel="noopener noreferrer">"Google to phase out third party cookies"</a><br/>
            ICON Case: <Link to="/works/project-firefly">Project Firefly Student Community Sponsored by Credit Suisse </Link></p>
           
            <p>With limited business travel and cancelled customer events,<strong> Virtual Events</strong> are exploding.  Done right, virtual events actually <strong>increase reach</strong> and <strong>improve engagement.</strong>
            The Frankfurt School of Finance shifted its' student event online in just days, reaching students who wouldn't otherwise have been able to travel to the school and posting
            content online before and after the event.</p>

            <h2>Learn more:</h2>
            <p>
            Article: <a  href="https://www.frankfurt-school.de/en/home/blogroom/blog/2020/April/online-bachelor-day" target="_blank" rel="noopener noreferrer"> "Frankfurt School "First Online Bachelor Day"</a><br/>
            Podcast: <a  href="https://digiday.com/media/mit-technology-review-events-streaming-revenue-interactivity-networking/" target="_blank" rel="noopener noreferrer">"How MIT Technology Review shifted its largest event to streaming"</a><br/>
            ICON Blog: <Link to="/blog/the-future-of-your-customer-relationship">"The Future of Customer Relationships is Remote"</Link></p>
            </div>
            <div className="insights-learn">
                <img src={insights1} alt="Zero Party Data, ICON Worldwide Blog, nearsore development" title="ICON Worldwide Blog, nearshore development, Zero Party Data" className="insights-img"/>
                <div className="develpment-text">
                    <h2>Nearshore Development</h2>
                    <p>Work with local project managers to:</p>
                    <ul>
                        <li>Reduce costs by up to 50%</li>
                        <li>Expand your technology stack</li>
                        <li>Improve your on-time delivery</li>
                    </ul>
                    <p><Link to="/services/nearshore-software-development/">Read more</Link></p>
                </div>
            </div>

            <div className="insights-learn">
                <img src={insights2} alt=" Zero Party Data, ICON Worldwide Blog, Full-Stack Development" title="ICON Worldwide Blog, Full-Stack Development, Zero Party Data" className="insights-img"/>
                <div className="develpment-text">
                    <h2>Full-Stack Development</h2>
                    <p>Designed for agencies and brands:</p>
                    <ul>
                        <li>Drupal & Acquia Certified</li>
                        <li>EMagnolia CMS</li>
                        <li>PHP & Java</li>
                        <li>iOS & Android</li>
                        <li>React & Angular</li>
                    </ul>
                    <p><Link to="/services">Read more</Link></p>
                </div>
            </div>

            <img src={arrow} id="single-news-horizontal-arrow" alt="ICON Worldwide arrow" title="ICON Worldwide arrow"/>
            <h4><Link to="/contact-us" className="cta cta-long">Let's start a new project together</Link></h4>

            <NewsFooter previous="why-invest-in-nearshore-software-development" next="the-future-of-your-customer-relationship"/>
        
    </div>
    </div>

    <Footer noScrollbar="true"/>
</div>